import React, { Component } from 'react'
import { rewriteContentfulImageUrl } from '../../utils/contentfulImage'
import { ITopicPropsProvided } from '../connectors/connect-topics'

interface IProps extends ITopicPropsProvided {
  hit: any
}

class ResourceHitCompact extends Component<IProps> {
  public props: IProps

  constructor(props: IProps, context?: any) {
    super(props, context)
  }

  public render() {
    const { title,
            heroImage,
            thumbnailImage,
            primary_topic,
            tags,
            author,
          } = this.props.hit

    let url: string
    if (heroImage && heroImage.file) {
      url = heroImage.file.url
    } else if (thumbnailImage && thumbnailImage.file) {
      url = thumbnailImage.file.url
    }
    if (url) {
      url = rewriteContentfulImageUrl(url, { w: 640, h: 360, fit: 'scale' })
    }

    const tag = this.getPrimaryTag(primary_topic ? [{ title: primary_topic }] : tags, true)

    return(
      <div className= "col-12 col-sm-6 card resource-hit resource-hit__compact">
        <a className="resource-hit__container card-horizontal-column__card-container fitted" href={this.getUrl()}>
          {tag}
          <div className="card-horizontal-column__card-image embed-responsive embed-responsive-16by9">
            { url && <img src={url} className="card-img embed-responsive-item" /> }
          </div>
          <div className="card-img-overlay card-horizontal-column__title">
            <h4>{title}</h4>
            {author && author.fullName &&
              <span className="resource-hit__author">{author.fullName}</span>}
          </div>
        </a>
      </div>
    )
  }

  private getUrl() {
    const { slug } = this.props.hit

    return `/blog${slug}`
  }

  private getPrimaryTag(tags: any[], pinned: boolean) {
    const className = pinned ? 'tag-primary pinned' : 'tag-secondary'
    const { refine } = this.props

    if (tags && tags.length > 0) {
      const key = tags[0].title
      return <span onClick={(e) => { e.preventDefault(); refine(key) }} className={className}>{key}</span>
    }

    return null
  }
}

export default ResourceHitCompact
