import React, { Component } from 'react'
import { HitsProvided } from 'react-instantsearch-core'
import { connectHits } from 'react-instantsearch-dom'
import ListItem from './list-item'
import { ILocation } from './types'

type Props = HitsProvided<ILocation> & {
  wrapperClass?: string,
  onLocationSelect: (location: ILocation) => void,
}

class LocationHits extends Component<Props> {
  public render() {
    const { hits, onLocationSelect, wrapperClass } = this.props
    const items = hits.map((hit) =>
      <ListItem key={hit.objectID} hit={hit}
        onLocationSelect={ onLocationSelect } />,
    )

    return (
      <div className={wrapperClass}>
        {items}
      </div>
    )
  }
}

export default connectHits(LocationHits)
