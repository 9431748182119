import React from 'react'
import { CurrentRefinementsProvided } from 'react-instantsearch-core'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

const ClearRefinements: React.FunctionComponent<CurrentRefinementsProvided> = (props) => {
  const { items, refine } = props

  return (
    <span className="widget-resources-search__refinements-clear"
          onClick={() => (refine as any)(items)}>
      Clear All
    </span>
  )
}

export default connectCurrentRefinements(ClearRefinements)
