import React, { Component } from 'react'
import { InfiniteHitsProvided } from 'react-instantsearch-core'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import NoHits from '../no-hits'
import ResourceHit from './resource-hit'
import ResourceHitCompact from './resource-hit-compact'

type Props = InfiniteHitsProvided & {
  shouldHideButton?: boolean,
  tagAttribute?: string
  useCompact?: boolean,
}

class ResourceHits extends Component<Props> {
  public render() {
    const {
      hits,
      refine,
      hasMore,
      shouldHideButton,
      useCompact,
      tagAttribute } = this.props

    if (hits.length == 0) {
      return (
        <div className="widget-resources-search__hits">
          <NoHits />
        </div>
      )
    }

    let buttonElement
    if (hasMore && !shouldHideButton) {
      buttonElement = <div className="col-12 widget-stories-of-hope-search__load-more">
        <button className="btn btn-secondary" onClick={refine} disabled={!hasMore}>
          Load More Resources
          </button>
      </div>
    }

    const containers = hits.map((hit, index) => {
      return useCompact ? <ResourceHitCompact hit={hit} key={index} /> :
        <ResourceHit attribute={tagAttribute} hit={hit} key={index} />
    })

    return <>
      <div className="row widget-resources-search__hits card-horizontal-column">
        {containers}
      </div>

      {buttonElement}
    </>
  }
}

export default connectInfiniteHits<Props, any>(ResourceHits)
