import React, { Component } from 'react'

class Loader extends Component {
  public render() {
    return (
      <div className="contentful-view">
        <div className="contentful-view__loader"></div>
      </div>
    )
  }
}

export default Loader
