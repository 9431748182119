import * as qs from 'qs'
import React, { Component } from 'react'
import { Configure, Index } from 'react-instantsearch/dom'
import { AlgoliaSearch } from '@watermarkchurch/react-instantsearch-components'
import WCC from 'wcc'

import LocationHits from '../locations/location-hits'
import { ILocation } from '../locations/types'
import MessageHits from '../messages-search/message-hits'
import CustomSearchBox from '../resources-search/custom-search-box'
import ResourceHits from '../resources-search/resource-hits'

interface IProps {
  hitsPerPage: number

  messagesIndex?: string
  blogsIndex?: string
  locationsIndex?: string
}

class SiteSearch extends Component<IProps> {
  public render() {
    const { hitsPerPage, messagesIndex, blogsIndex, locationsIndex } = this.props

    return (
      <div className="row justify-content-center">
        <div className="col-content-container">
          <AlgoliaSearch indexName={messagesIndex || 'Messages_production'} widgetName="site-search" >
            <div className="row search-bar">
              <div className="col-sm-6 searchbox-column">
                <CustomSearchBox placeholder="Search for anything..." />
              </div>
            </div>
            <div>
              <section className="default">
                <Index indexName={messagesIndex || 'Messages_production'}>
                  <h3>Stories of Hope</h3>
                  <Configure filters={'tags:"reengage.org - Stories"'} hitsPerPage={hitsPerPage} />
                  <MessageHits paginate={false} />
                </Index>
              </section>
              <section className="default">
                <Index indexName={blogsIndex || 'papyrus_heroku-production_blogs'}>
                  <h3>Resources</h3>
                  <Configure filters={`publishedProperties.key: ${WCC.CONSTANTS.BLOGGING_SUBSYSTEM_PUBLISHING_TARGET}`}
                    hitsPerPage={hitsPerPage} />
                  <ResourceHits shouldHideButton={true} useCompact />
                </Index>
              </section>
              <section className="default">
                <Index indexName={locationsIndex || 'wmresources_heroku_production_ministry_locations'}>
                  <h3>Locations</h3>
                  <Configure filters={'ministry:"re|engage"'} hitsPerPage={4} />
                  <LocationHits wrapperClass="widget-location-items" onLocationSelect={this.onLocationSelect} />
                </Index>
              </section>
            </div>
          </AlgoliaSearch>
        </div>
      </div>
    )
  }

  private onLocationSelect(location: ILocation) {
    const query = {
      mapBounds: {
        selectedLocation: {
          id: location.objectID,
          title: location.title,
          lat: location._geoloc.lat,
          lng: location._geoloc.lng,
        }
      }
    }
    window.location.assign(`/locations?${qs.stringify(query)}`)
  }
}

export default SiteSearch
