import React, { Component } from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import SimilarHit from './similar-hit'

interface IProps {
  hits: any,
}

class SimilarHits extends Component<IProps> {
  public render() {
    const { hits } = this.props

    const containers = hits.map((hit, index) => {
      return <SimilarHit key={index} hit={hit} />
    })

    return(
      <div className="row card-horizontal-column">
        {containers}
      </div>
    )
  }
}

export default connectInfiniteHits(SimilarHits)
