export default function() {
  $(document).ready(() => {
    $('#email-subscription').each((_, input) => {
      const $form = $(input)

      function handleResponse(event: any, status: any, xhr: any) {
        let json
        try {
          if (event.detail) {
            json = JSON.parse(event.detail[2].response)
            status = event.detail[1]
          } else {
            json = xhr.responseJSON
          }
        } catch (ex) {
          json = {
            message: 'An unexpected error occurred!  Please try again later.',
            errors: '',
          }
        }

        $form.append(
          $('<div>').text(json.message).delay(5000).fadeOut(),
        )

        if (!json.errors) { $('input[type=email]', $form).val('') }
      }

      $form.on('ajax:success', (event, data, status, xhr) => {
        handleResponse(event, status, xhr)
      })

      $form.on('ajax:error', (event, xhr, status) => {
        handleResponse(event, status, xhr)
      })
    })
  })
}
