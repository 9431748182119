import React, { Component } from 'react'
import { CurrentRefinementsProvided, RefinementValue } from 'react-instantsearch-core'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

interface IProps extends CurrentRefinementsProvided {
  acceptedTags: Array<{key: string, title: string}>,
}

class CustomCurrentRefinements extends Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  public render() {
    const processedItems = {}

    const items = this.props.items.map((item, idx) => {
      // Did we already spit out something?
      if (processedItems[item.attribute]) {
        return
      }
      processedItems[item.attribute] = true

      /*
       * When existing several refinements for the same atribute name, then you get a
       * nested items object that contains a label and a value function to use to remove a single filter.
       * https://community.algolia.com/react-instantsearch/connectors/connectCurrentRefinements.html
       */
      if ('items' in item) {
        return <div key={idx}>
          {item.items.map((subItem, subIdx) => {
            return this.renderRefinement(subItem, subIdx.toString())
          })}
        </div>
      }

      return <div key={idx}>
        {this.renderRefinement(item)}
      </div>
    })

    return(
      <div className="widget-resources-search__refinements__current-refinements">
        {items}
      </div>
    )
  }

  private renderRefinement(
    subItem: { label: string, value: RefinementValue },
    key?: string,
  ) {
    if (subItem.label == 'null') {
      return
    }

    return(
      <span className="tag-secondary" key={key} onClick={this.refineItem.bind(this, subItem)}>
        {this.getTitleForTag(subItem.label)} <i className="material-icons">close</i>
      </span>
    )
  }

  private refineItem = (item: { value: RefinementValue }) => {
    const { refine } = this.props

    refine(item.value)
  }

  private getTitleForTag(label: string): string {
    const { acceptedTags } = this.props

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < acceptedTags.length; i++) {
      if (label == acceptedTags[i].key) {
        return acceptedTags[i].title
      }
    }

    return label
  }
}

export default connectCurrentRefinements(CustomCurrentRefinements)
