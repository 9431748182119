import bugsnagClient from 'bugsnag'
import * as React from 'react'

declare function bugsnag__react(...args: any[]): any

let BugsnagErrorBoundary

if (bugsnagClient && !(bugsnagClient as any).fake && (typeof bugsnag__react != 'undefined')) {
  BugsnagErrorBoundary = bugsnagClient.use(bugsnag__react(React))
}

export const ErrorBoundary = ({ children }) => {
  if (BugsnagErrorBoundary) {
    return <BugsnagErrorBoundary FallbackComponent={FallbackComponent} beforeSend={beforeSend}>
      {children}
    </BugsnagErrorBoundary>
  }
  return <DevErrorBoundary>
    {children}
  </DevErrorBoundary>
}

const FallbackComponent = ({ error, info }) => (
  <div className="row error-fallback">
    <div className="col">
      <span className="error">
        An error occurred in rendering this component!  Please try refreshing the page.
      </span>
    </div>
  </div>
)

class DevErrorBoundary extends React.Component<{}, {error?: Error, info?: any}> {
  constructor(props) {
    super(props)
    this.state = { }
  }

  public componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ error, info })
  }

  public render() {
    const {error, info} = this.state
    if (error) {
      return <div className="row">
      <div className="col">
        <h1 className="error">{error.message}</h1>
        <pre>{error.stack}</pre>
        <hr />
        <pre>{info && info.componentStack}</pre>
      </div>
      </div>
    }
    return this.props.children
  }
}

function beforeSend(report) {
  console.error(report)
}
