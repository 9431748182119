import React, { Component } from 'react'
import { SearchBoxProvided } from 'react-instantsearch-core'
import { connectSearchBox } from 'react-instantsearch-dom'

interface IProps extends SearchBoxProvided {
  currentRefinement: any
  refine: any
  placeholder: string,
  onUpdate?: any
  autoFocus?: boolean
}

class CustomSearchBox extends Component<IProps> {
  public static defaultProps = {
    autoFocus: true,
  }

  constructor(props: CustomSearchBox['props']) {
    super(props)
  }

  public componentDidMount() {
    if (this.props.autoFocus && (!this.props.currentRefinement || !this.props.currentRefinement.length)) {
      const input = $('.ais-SearchBox-input')

      if (input.length) {
        setTimeout(() => input.trigger('touchstart focus').focus(), 0)
      }
    }
    this.setState({
      mounted: true,
    })
  }

  public render() {
    const { currentRefinement, placeholder } = this.props

    return(
      <div className="searchbox">
        <i className="material-icons">search</i>
        <input  type="search"
                className="ais-SearchBox-input"
                value={currentRefinement}
                onChange={(e) => this.onSearchTextChanged(e)}
                placeholder={placeholder}
        />
      </div>
    )
  }

  private onSearchTextChanged(e: React.ChangeEvent<HTMLInputElement>) {
    const { refine, onUpdate } = this.props

    refine(e.currentTarget.value)
    if (onUpdate) {
      onUpdate(e.currentTarget.value)
    }
  }
}

export default connectSearchBox(CustomSearchBox)
