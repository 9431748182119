interface IAddress {
  street1: string
  street2?: string
  city: string
  state: string
  zip: string
  country: string
}

interface IContact {
  name: string
  phone: string
  email: string
}

interface ILocation {
  address: IAddress
  contact: IContact
  description: string
  _geoloc: google.maps.LatLngLiteral
  meetingDay: Dayname
  meetingTime: string
  thumbnailUrl?: string
  title: string
  trainingLocation?: boolean
  has_spanish?: boolean
  website: string
  objectID: string
  hasChildcare: boolean
}

// rails Date::DAYNAMES
export type Dayname = 'Sunday' | 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday'
export const Daynames: Dayname[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
]

interface IDomainObject {
  id: string
  title: string
  type: string
}

export {
  IAddress,
  ILocation,
  IDomainObject,
}
