import React, { Component } from 'react'

interface IProps {
  icon: string
  url: string
}

class MessageIcon extends Component<IProps> {
  public props: IProps

  constructor(props: IProps, context?: any) {
    super(props, context)
  }

  public render() {
    const { icon, url } = this.props

    return(
      <span
        onClick={(e) => {
          e.preventDefault()
          window.location.href = url
        }}
      >
        <i className="material-icons">{icon}</i>
      </span>
    )
  }
}

export default MessageIcon
