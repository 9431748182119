import React, { Component } from 'react'
import { Configure } from 'react-instantsearch/dom'
import {AlgoliaSearch} from '@watermarkchurch/react-instantsearch-components'
import SimilarHits from './similar-hits'

interface IProps {
  hitsPerPage: number
  forcedFacets: string
  index: string
  title: string
  excludeID: string
}

class SimilarResources extends Component<IProps> {
  constructor(props: IProps) {
    super(props)
  }

  public render() {
    const {
      title,
      hitsPerPage,
      forcedFacets,
      index,
      excludeID } = this.props

    let filter = forcedFacets
    // Ugly exclusion
    if (excludeID) {
      filter += ` AND NOT objectID:${excludeID}`
    }

    return (
      <div className="row justify-content-center">
        <div className="col-content-container">
          <div className="row section-card-deck__header">
            <div className="col-content-narrow p-0">
              <div className="section-card-deck__header-text">
                <div className="formatted-content">
                  <h3>{title}</h3>
                </div>
              </div>
            </div>
          </div>
          <AlgoliaSearch indexName={index} widgetName="similar-resources__search">
            <Configure  filters={filter}
                        hitsPerPage={hitsPerPage} />
            <SimilarHits />
          </AlgoliaSearch>
        </div>
      </div>
    )
  }
}

export default SimilarResources
