import { GoogleAPI } from 'google-maps-react'
import * as React from 'react'

import { Daynames, IAddress } from './types'
import { calculateDistanceText, ILocation, IVirtualEvent, ListItemProps } from '@watermarkchurch/location-map'

interface IState {
}

class MapListItem extends React.Component<ListItemProps, IState> {
  constructor(props: ListItemProps) {
    super(props)

    this.state = {
      hovered: false,
    }
  }

  public onMouseEntered() {
    this.setState({ hovered: true })
  }

  public onMouseLeft() {
    this.setState({ hovered: false })
  }

  public render() {
    const { hit } = this.props

    return (
        <div className="churches-list__item__content">
          <div className="churches-list__item__content__details">
          { hit.trainingLocation && this.renderTrainingBadge() }
          { hit.has_spanish && this.renderSpanishBadge() }

          <h4 className="churches-list__item__content__title">
            { hit.title }
          </h4>

          <i className="material-icons">place</i>
          <span className="churches-list__item__content__text">
            { this.renderDetails(hit) }
          </span>
          </div>

          <div className={'churches-list__item__content__arrow'}>
            <i className="material-icons">arrow_forward</i>
          </div>
        </div>
    )
  }

  private renderTrainingBadge() {
    return <span className="churches-list__item__content__utility">
      <i className="icon ion-android-star"></i>
      Training Location
    </span>
  }

  private renderSpanishBadge() {
    return <span className="churches-list__item__content__utility">
      En Español
    </span>
  }
  
  private renderDetails(location: ILocation | IVirtualEvent): string {
    const { google, currentRefinement, userLocation } = this.props
    
    let distanceText = '';
    if (isNonVirtual(location)) {
      distanceText = calculateDistanceText({
        google,
        translate: this.props.translate,
        currentRefinement,
        userLocation,
        hit: location,
      })
    }
    
    const results = [
      renderShortAddress(location.address),
      distanceText,
      renderMeetingTime(location)
    ].filter((x) => !!x)
    return results.join(' \u2022 ')
  }
}

export default MapListItem




export function renderShortAddress(address: IAddress) {
  return [
    address.city, address.state,
  ].join(', ')
}

export function renderMeetingTime(location: ILocation | IVirtualEvent): string {
  const day = Daynames.indexOf(location.meetingDay)
  const parts = []
  if (day != -1) {
    const meetingDay = window.I18n.t(
      ['locationMap', 'meetingDay', 'days_of_week', day as any],
      { defaultValue: location.meetingDay },
    )
    parts.push(meetingDay)
  }

  if (location.meetingTime && location.meetingTime.length > 0) {
    parts.push(location.meetingTime)
  }
  // TODO: i18n.t(meetingTime)

  return parts.join(' @ ')
}

function isNonVirtual(location: ILocation | IVirtualEvent): location is ILocation {
  return !location.isVirtual
}

