import React from 'react'
import * as I18n from 'i18n-js'
import aa from 'search-insights'

import connectGoogleMaps from '../lib/connectors/googleMaps'

import LocationMapImpl from '@watermarkchurch/location-map'
import MapListItem from '../lib/components/locations/map-list-item'

const defaults: { apiKey?: string, appId?: string } = {}
if (typeof window != 'undefined' && (window as any).WCC) {
  const constants = (window as any).WCC.CONSTANTS
  defaults.apiKey = constants.ALGOLIA_SEARCH_API_KEY
  defaults.appId = constants.ALGOLIA_APP_ID
}

interface IProps {
  indexName: string
  preview?: boolean
}

const ConnectedLocationMap = connectGoogleMaps(LocationMapImpl) as any

export default function LocationMap(props: IProps) {
  aa('init', {
    appId: defaults.appId,
    apiKey: defaults.apiKey,
  })

  return <ConnectedLocationMap {...props}
    ListItem={MapListItem}
    domainObject={{
      title: 're|engage',
      type: 'ministry',
    }}
    algoliaApiKey={defaults.apiKey}
    algoliaAppId={defaults.appId}
    translate={I18n.translate.bind(I18n)}
    sendAnalytics={!props.preview}
    aa={aa} />
}

