export default function() {
  const handleEmptyClass = ($input) => {
    if ($input.val() == '') {
      $input.removeClass('not-empty')
    } else {
      $input.addClass('not-empty')
    }
  }

  $(document).ready(() => {
    $('.js-focus-label-input')
      .blur(function() { handleEmptyClass($(this)) })
      .each((_, input) => handleEmptyClass($(input)))
  })
}
