import { SearchState } from 'react-instantsearch-core'

export const addRefinementToSearchState = (facet: string, value: string, state: SearchState): SearchState => {
  if (!facet) {
    return state
  }

  let list = state.refinementList
  if (!state.refinementList) {
    list = {}
  }

  let existingRefinements = list[facet]
  if (!existingRefinements) {
    existingRefinements = []
  }

  // If it already exists in the list, do not refine
  if (existingRefinements.indexOf(value) !== -1) {
    return state
  }

  existingRefinements.push(value)
  list[facet] = existingRefinements
  state.refinementList = list

  return state
}

export function present<T>(value: T): value is Exclude<T, false | undefined | null | ''> {
  if (typeof value == 'string') {
    return value.length > 0
  }

  return !!value
}
