import { createConnector } from 'react-instantsearch'
import { SearchState } from 'react-instantsearch-core'
import { addRefinementToSearchState } from '../../utils'

export interface ITopicPropsProvided {
  hit: any
  attribute?: string
  refine?: (...args: any[]) => any
}

export interface ITopicPropsExposed {
  attribute?: string
  hit: any
}

export const connectTopics = createConnector<ITopicPropsProvided, ITopicPropsExposed>({
  displayName: 'TopicsConnector',

  getProvidedProps(
    props: ITopicPropsExposed,
    searchState: SearchState,
    { results },
  ) {
    return {
      hit: props.hit,
      attribute: props.attribute,
    }
  },

  refine(props: ITopicPropsExposed, searchState: SearchState, nextRefinement: any): SearchState {
    const { attribute } = props

    return addRefinementToSearchState(attribute, nextRefinement, searchState)
  },
})
