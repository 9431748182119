import React, { Component } from 'react'
import { InfiniteHitsProvided } from 'react-instantsearch-core'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import { IMessageDoc } from '../../models/algolia/message'
import NoHits from '../no-hits'
import MessageHit from './message-hit'

type Props = InfiniteHitsProvided<IMessageDoc> & {
  paginate?: boolean,
  topicAttribute?: string,
}

class MessageHits extends Component<Props> {
  public render() {
    const {
      hits,
      refine,
      hasMore,
      paginate,
      topicAttribute } = this.props

    if (hits.length == 0) {
      return (
        <div className="messages-search__hits">
          <NoHits />
        </div>
      )
    }

    let buttonElement
    if (paginate && hasMore) {
      buttonElement = (
        <div className="col-12 widget-stories-of-hope-search__load-more">
          <button className="btn btn-secondary" onClick={refine} disabled={!hasMore}>
            Load More Stories of Hope
          </button>
        </div>
      )
    }

    const hitsPerRow = this.eachSlice(hits, 2)

    return (
      <>
        <div className="messages-search__hits horizontal-card-deck">
          {
            hitsPerRow.map((rowHits) =>
              <div className="horizontal-card-deck__2-wide"
                key={`message-row-${rowHits.map((h) => h.id)}`}>
                {rowHits.map((hit, index) =>
                  <div key={index} className="col-12 col-sm-6 card card-horizontal">
                    <MessageHit attribute={topicAttribute} hit={hit} />
                  </div>,
                )}
              </div>,
            )
          }
        </div>
        {buttonElement}
      </>
    )
  }

  public eachSlice = (hits, size) => {
    const arr = []
    for (let i = 0, l = hits.length; i < l; i += size) {
      arr.push(hits.slice(i, i + size))
    }
    return arr
  }
}

export default connectInfiniteHits<Props, IMessageDoc>(MessageHits)
