
export interface IContentfulImageOptions {
  fm?: 'jpg' | 'png' | 'webp',
  fl?: 'progressive' | 'png8',
  /** width */
  w?: number,
  /** height */
  h?: number,
  /** fit (see MDN on object-fit) */
  fit?: 'pad' | 'fill' | 'scale' | 'crop' | 'thumb',
  /** focus */
  f?: 'center' | 'top' | 'right' | 'left' | 'bottom' | 'top_right' | 'top_left' | 'bottom_right' | 'bottom_left'
    | 'face' | 'faces',
  /** radius:  */
  r?: number | 'max',
  /** quality: 1...100 */
  q?: number,
  /** background color: rgb:ffffff */
  bg?: string
}

export function rewriteContentfulImageUrl(url: string, options: IContentfulImageOptions): string {
  const u = new URL(url, document.baseURI)
  const keys = Object.keys(options) as Array<keyof IContentfulImageOptions>
  keys.forEach((key) => {
    const val = options[key]
    if (val) {
      u.searchParams.set(key, val.toString())
    } else {
      u.searchParams.delete(key)
    }
  })

  return u.toString()
}
