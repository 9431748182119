import '../javascripts/global/index'
import wrapTopLevelComponent from '../javascripts/lib/wrap-top-level-component'
import ReactRailsUJS from 'react_ujs'

const componentRequireContext = import.meta.glob('../javascripts/top-level-components/*.tsx', { eager: true })

var wrappedGetConstructor = ReactRailsUJS.getConstructor

ReactRailsUJS.getConstructor = function() {
  var ctor = wrappedGetConstructor.apply(this, arguments)
  return wrapTopLevelComponent(ctor)
}

// React-Rails is technically designed to be used with Webpacker. The code below enables us to mount the components to the window by component name instead of filename.

Object.keys(componentRequireContext).forEach((path) => {
  let component = componentRequireContext[path].default; 

  window[`${component.name.replace('../top-level-components/', "").replace('.tsx', "")}`] = component;
});
