import React, { Component } from 'react'
import {
  connectRefinementList,
  RefinementListProvided,
} from 'react-instantsearch-core'

interface IProps extends RefinementListProvided  {
  title: string,
}

interface IState {
  isOpen: boolean
}

export class CheckboxRefinementList extends Component<IProps, IState> {

  private component: any

  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  // Clickoff logic
  public componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  public render() {
    const { title, items } = this.props
    const { isOpen } = this.state

    let arrow = 'arrow_drop_down'
    let options = null
    if (isOpen) {
      arrow = 'arrow_drop_up'
      options = this.getOptions()
    }

    let boxClass = 'search-dropdown__box'
    if (items.length == 0) {
      boxClass += ' disabled'
    }

    return(
      <>
        <div className="search-dropdown search-dropdown-checkbox" ref={(component) => this.component = component}>
          <div className={boxClass} onClick={this.onBoxClick.bind(this)}>
            <div className="search-dropdown__title">
              {title}
            </div>
            <div className="search-dropdown__arrow">
              <i className="material-icons">{arrow}</i>
            </div>
          </div>
          {options}
        </div>
      </>
    )
  }

  private getOptions(): React.ReactElement {
    // always sorted alphabetically regardless of selected status
    const items = this.props.items.sort((a, b) => a.label.localeCompare(b.label))

    const topicOptions = items.map((item, index) => {
      // Skip beacuse this can be passed in via defaultRefinement
      if (item.label == 'null') {
        return
      }

      return (
        <div className={`search-dropdown-checkbox__option ${item.isRefined && 'selected'}`} key={item.objectID}
            onClick={() => { 
              this.props.refine(item.value)
            }}>
          <span>{this.getPrettyLabel(item.label)}</span>
        </div>
      )
    })

    if (topicOptions.length == 0) {
      return(
        <div className="search-dropdown-checkbox__options">
          <h3>None Available</h3>
        </div>
      )
    }

    return (
      <div className="search-dropdown-checkbox__options">
        {topicOptions}
      </div>
    )
  }

  private onBoxClick() {
    this.setState({isOpen: !this.state.isOpen})
  }

  private getPrettyLabel(label) {
    return label.replace('_', ' ')
  }

  private handleClick = (e: any) => {
    if (this.component.contains(e.target)) {
      return
    }

    this.setState({isOpen: false})
  }
}

export default connectRefinementList(CheckboxRefinementList)
