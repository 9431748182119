import { GoogleAPI } from 'google-maps-react'
import * as React from 'react'

import { Daynames, IAddress, ILocation } from './types'

interface IProps {

  hit: ILocation

  onLocationSelect: (location: ILocation) => void
}

interface IState {
}

class ListItem extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      hovered: false,
    }
  }

  public onMouseEntered() {
    this.setState({ hovered: true })
  }

  public onMouseLeft() {
    this.setState({ hovered: false })
  }

  public render() {
    const { hit } = this.props

    const onClick = (e: any) => {
      e.preventDefault()
      this.props.onLocationSelect(hit)
    }

    return (
      <a className="churches-list__item__link" onClick={onClick}
        onMouseEnter={this.onMouseEntered.bind(this)} onMouseLeave={this.onMouseLeft.bind(this)}>
        <div className="churches-list__item__content">
          <div className="churches-list__item__content__details">
          { hit.trainingLocation && this.renderTrainingBadge() }
          { hit.has_spanish && this.renderSpanishBadge() }

          <h4 className="churches-list__item__content__title">
            { hit.title }
          </h4>

          <i className="material-icons">place</i>
          <span className="churches-list__item__content__text">
            { renderDetails(hit) }
          </span>
          </div>

          <div className={'churches-list__item__content__arrow'}>
            <i className="material-icons">arrow_forward</i>
          </div>
        </div>
      </a>
    )
  }

  private renderTrainingBadge() {
    return <span className="churches-list__item__content__utility">
      <i className="icon ion-android-star"></i>
      Training Location
    </span>
  }

  private renderSpanishBadge() {
    return <span className="churches-list__item__content__utility">
      En Español
    </span>
  }
}

export default ListItem


function renderDetails(location: ILocation): string {
  const results = []
  results.push(renderShortAddress(location.address), renderMeetingTime(location))
  return results.join(' \u2022 ')
}

export function renderShortAddress(address: IAddress) {
  return [
    address.city, address.state,
  ].join(', ')
}

export function renderMeetingTime(location: ILocation): string {
  const day = Daynames.indexOf(location.meetingDay)
  const parts = []
  if (day != -1) {
    const meetingDay =window.I18n.t(
      ['locationMap', 'meetingDay', 'days_of_week', day as any],
      { defaultValue: location.meetingDay },
    )
    parts.push(meetingDay)
  }

  if (location.meetingTime && location.meetingTime.length > 0) {
    parts.push(location.meetingTime)
  }
  // TODO: i18n.t(meetingTime)

  return parts.join(' @ ')
}
