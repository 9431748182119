import React, { Component } from 'react'
import { rewriteContentfulImageUrl } from '../../utils/contentfulImage'
import { connectTopics, ITopicPropsProvided } from '../connectors/connect-topics'

interface IProps extends ITopicPropsProvided {
  hit: any,
}

class ResourceHit extends Component<IProps> {
  public props: IProps

  constructor(props: IProps, context?: any) {
    super(props, context)
  }

  public render() {
    const { title,
            heroImage,
            thumbnailImage,
            tags,
            author,
          } = this.props.hit

    let url: string
    if (heroImage && heroImage.file) {
      url = heroImage.file.url
    } else if (thumbnailImage && thumbnailImage.file) {
      url = thumbnailImage.file.url
    }
    if (url) {
      url = rewriteContentfulImageUrl(url, { w: 640, h: 360, fit: 'scale' })
    }

    const tag = this.getPrimaryTag(tags, false)
    const pinnedTag = this.getPrimaryTag(tags, true)
    const tagLink = '#' + tag

    return(
      <div className="resource-hit">
      <a href={this.getUrl()}>
        <div className="d-none d-sm-flex">
          <div className="col-12 col-sm-6 col-xl-4 p-0 embed-responsive embed-responsive-16by9">
              {url && <img className="resource-hit__image embed-responsive-item" src={url}/>}
          </div>
          <div className="col-12 col-sm-6 col-xl-8">
            {tag}
            <h4>{title}</h4>
            {author && author.fullName &&
              <span className="resource-hit__author">{author.fullName}</span>}
          </div>
        </div>
        <div className="d-block d-sm-none">
          <div className="resource-hit__container embed-responsive embed-responsive-16by9">
            <a href={tagLink}>
              {pinnedTag}
            </a>
            {url && <img className="resource-hit__image embed-responsive-item" src={url}/>}
          </div>
            <h4 className="resource-hit__title">{title}</h4>
            {author && author.fullName &&
              <span className="resource-hit__author">{author.fullName}</span>}
        </div>
      </a>
      </div>
    )
  }

  private getUrl() {
    const { slug } = this.props.hit

    return `/blog${slug}`
  }

  private getPrimaryTag(tags: Array<{key: string, title: string}>, pinned: boolean) {
    const className = pinned ? 'tag-primary pinned' : 'tag-secondary'
    const { refine } = this.props

    if (tags && tags.length > 0) {
      const {key, title} = tags[0]
      return <span onClick={(evt) => { evt.preventDefault(); refine(key) }} className={className}>{title}</span>
    }

    return null
  }
}

export default connectTopics(ResourceHit)
