import bugsnagClient from 'bugsnag'
import React, { Component } from 'react'
import Loader from './loader'

interface IProps {
  contenfulID: string
  classWrapper?: string
}

interface IState {
  html: string
}

class ContentfulView extends Component<IProps, IState> {
  public static fetchRenderedContenfulSection(url: string, cb: (body) => void) {
    fetch(url)
      .then((resp) => {
        if (resp.ok) {
          return resp.text()
        } else {
          throw Error(`Invalid response ${resp.status}`)
        }
      })
      .then(cb)
      .catch((e) => {
        bugsnagClient.notify(e)
        console.error(e)
      })
  }

  public static getSectionUrl(contenfulID): string {
    return `${window.location.origin}/sections/${contenfulID}`
  }

  constructor(props) {
    super(props)

    this.state = {
      html: null,
    }
    ContentfulView.fetchRenderedContenfulSection(
      ContentfulView.getSectionUrl(this.props.contenfulID), this.setHTML,
    )
  }

  public render() {
    const { html } = this.state

    if (!html) {
      return <Loader />
    }

    return (
      <div className={this.props.classWrapper} dangerouslySetInnerHTML={this.getHtml()}></div>
    )
  }

  private getHtml() {
    const { html } = this.state

    if (!html || html.length == 0) {
      return null
    }

    return { __html: html }
  }

  private setHTML = (body) => this.setState({html: body})
}

export default ContentfulView
export {
  ContentfulView,
  Loader,
}
