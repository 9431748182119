import React, { Component } from 'react'
import { IMessageDoc } from '../../models/algolia/message'
import { connectTopics, ITopicPropsProvided } from '../connectors/connect-topics'
import MessageIcon from './message-icon'

interface IProps extends ITopicPropsProvided {
  hit: IMessageDoc
}

class MessageHit extends Component<IProps> {
  public props: IProps

  constructor(props: IProps, context?: any) {
    super(props, context)
  }

  public render() {
    const { title,
      thumbnail_url,
      description,
    } = this.props.hit

    const icons = []

    if (this.hasVideo()) {
      icons.push(
        <MessageIcon key={icons.length}
          icon="play_arrow"
          url={this.getUrl() + '?embed=video'} />,
      )
    }

    if (this.hasAudio()) {
      icons.push(
        <MessageIcon key={icons.length}
          icon="volume_up"
          url={this.getUrl() + '?embed=audio'} />,
      )
    }

    if (this.hasSermonGuide()) {
      icons.push(
        <MessageIcon key={icons.length}
          icon="notes"
          url={this.getUrl() + '?embed=sermon'} />,
      )
    }

    const topic = this.getPrimaryTopic()

    return (
      <>
        <div className="message-hit__container">
          {topic}
          <a className="message-hit__image-link card-img-top" href={this.getUrl()}>
            <div className="message-hit__icons">{icons}</div>
            <img className="message-hit__image card-img" src={thumbnail_url} />
          </a>
        </div>
        <div className="formatted-content">
          <h4 className="message-hit__title"><a href={this.getUrl()}>{title}</a></h4>
          {description && <p className="message-hit__description">{description}</p> || ''}
        </div>
      </>
    )
  }

  private onTopicSelected(topic) {
    const { refine } = this.props

    if (topic) {
      refine(topic)
    }
  }

  private getPrimaryTopic() {
    const { topics } = this.props.hit

    // Note: Temporary data in case there are no topics
    if (topics.length === 0) {
      return null
    }

    const topic = topics[0]

    return (
      <span className="tag-primary pinned" onClick={this.onTopicSelected.bind(this, topic)}>
        {topic}
      </span>
    )
  }

  private getUrl(prefix: string = '/stories') {
    const { objectID, slug } = this.props.hit

    return `${prefix}/${objectID}-${slug}`
  }

  private hasVideo() {
    return this.hasMediaType('video')
  }

  private hasAudio() {
    return this.hasMediaType('audio')
  }

  private hasSermonGuide() {
    return this.hasMediaType('sermon_guide')
  }

  private hasMediaType(type: string) {
    const { media_types } = this.props.hit

    return media_types.indexOf(type) > -1
  }
}

export default connectTopics(MessageHit)
