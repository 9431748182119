import React, { Component } from 'react'
import { RefinementListProvided } from 'react-instantsearch-core'
import { connectRefinementList } from 'react-instantsearch-dom'

interface IProps extends RefinementListProvided {
  title: string,
}

interface IState {
  isOpen: boolean
}

class CustomRefinement extends Component<IProps, IState> {
  private component: any

  constructor(props: IProps) {
    super(props)

    this.state = {
      isOpen: false,
    }
  }

  // Clickoff logic
  public componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  public componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  public render() {
    const { title } = this.props
    const { isOpen } = this.state

    // always sorted alphabetically regardless of selected status
    const items = this.props.items.sort((a, b) => a.label.localeCompare(b.label))

    let arrow = 'arrow_drop_down'
    let options = null
    if (isOpen) {
      arrow = 'arrow_drop_up'
      options = this.getOptions()
    }

    let boxClass = 'search-dropdown__box'
    if (items.length == 0) {
      boxClass += ' disabled'
    }

    return (
      <>
        <div className="search-dropdown" ref={(component) => this.component = component}>
          <div className={boxClass} onClick={this.onBoxClick.bind(this)}>
            <div className="search-dropdown__title">
              {title}
            </div>
            <div className="search-dropdown__arrow">
              <i className="material-icons">{arrow}</i>
            </div>
          </div>
          <div className="d-none d-sm-block search-dropdown-options">
            {options}
          </div>
        </div>
        <div className="d-block d-sm-none search-dropdown-options">
          {options}
        </div>
      </>
    )
  }

  private getOptions(): React.ReactElement {
    const { items } = this.props

    const topicOptions = items.map((item, index) => {
      // Skip beacuse this can be passed in via defaultRefinement
      if (item.label == 'null') {
        return
      }

      let className = 'search-dropdown__option'
      // well this is ugly, can we use classNames for this?
      if (item.isRefined) {
        className += ' selected'
      }

      return (
        <div className="col-12 col-md-4 col-xl-3" key={index}>
          <div className={className} onClick={this.refineItem.bind(this, item)}>
            <span>{this.getPrettyLabel(item.label)}</span>
          </div>
        </div>
      )
    })

    if (topicOptions.length == 0) {
      return (
        <div className="search-dropdown__options">
          <h3>None Available</h3>
        </div>
      )
    }

    return (
      <div className="search-dropdown__options">
        <div className="row">
          {topicOptions}
        </div>
      </div>
    )
  }

  private refineItem(item) {
    const { refine } = this.props

    refine(item.value)
  }

  private onBoxClick() {

    this.setState({ isOpen: !this.state.isOpen })
  }

  private getPrettyLabel(label) {
    return label.replace('_', ' ')
  }

  private handleClick = (e: any) => {
    if (this.component.contains(e.target)) {
      return
    }

    this.setState({ isOpen: false })
  }
}

export default connectRefinementList(CustomRefinement)
