import React, { Component } from 'react'
import { Configure, Stats } from 'react-instantsearch/dom'
import {AlgoliaSearch} from '@watermarkchurch/react-instantsearch-components'
import CheckboxRefinementList from '../checkbox-refinement-list'
import { ContentfulView, Loader } from '../contenful-view'
import CustomClearRefinements from '../custom-clear-refinements'
import CustomCurrentRefinements from '../custom-current-refinements'
import CustomSearchBox from './custom-search-box'
import ResourceHits from './resource-hits'

interface IProps {
  hitsPerPage: number
  forcedFacets: string
  index: string
  contenfulSections: string[]
  acceptedTags: Array<{ key: string, title: string }>
}

interface IState {
  hasQuery: boolean
  renderedSections: { [id: string]: string }
}

class ResourcesSearch extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)

    this.state = {
      hasQuery: false,
      renderedSections: {},
    }

    this.getContenfulSections()
  }

  public render() {
    const { hitsPerPage, forcedFacets, index } = this.props

    return (
      <React.Fragment>
        <div className="row justify-content-center">
          <div className="col-content-container">
            <AlgoliaSearch indexName={index}
              widgetName="widget-resources-search"
              onSearchStatechange={this.onSearchStateChange}
              storeStateInQuery>
              <Configure filters={forcedFacets} hitsPerPage={hitsPerPage} />
              <div className="row justify-content-center">

                <div className="d-none d-md-flex col-md-8 widget-resources-search__query">
                  <CustomSearchBox placeholder="Search resources..." />
                </div>

                <div className="d-none d-md-flex col-md-4 widget-resources-search__query">
                  <CheckboxRefinementList attribute="tags.key"
                    title="Topic"
                    transformItems={this.transformTags}
                    limit={999} />
                </div>
                <div className="col-12 d-md-none mb-2">
                  <CustomSearchBox placeholder="Search resources..." />
                </div>
                <div className="col-12 d-md-none">
                  <CheckboxRefinementList attribute="tags.key"
                    title="Topic"
                    transformItems={this.transformTags}
                    limit={999} />
                </div>
              </div>
              {this.getRefinementControls()}
              {this.getResourceHits()}
            </AlgoliaSearch>
          </div>
        </div>
        {Object.keys(this.state.renderedSections).length &&
          <div className={this.shouldSectionsBeHidden() ? 'd-none' : ''}
            dangerouslySetInnerHTML={{ __html: this.renderOrderedSections() }}>
          </div> || ''
        }
        {!Object.keys(this.state.renderedSections).length &&
          <div className={this.shouldSectionsBeHidden() ? 'd-none' : ''}>
            <Loader />
          </div>
        }
      </React.Fragment>
    )
  }

  private renderOrderedSections() {
    if (!this.hasSections()) { return }

    return this.props.contenfulSections.map((id) => this.state.renderedSections[id] || '').join('')
  }

  private getResourceHits() {
    if (this.state.hasQuery) {
      return <ResourceHits tagAttribute="tags.key" />
    } else {
      return null
    }
  }

  private onSearchStateChange = (state) => {
    // Purpose of this function is to determine if we have a "query" or not.
    // A query is determined by having any refinement set, or to have a
    // query set as a string

    const { refinementList, query } = state

    if (query && query.length > 0) {
      this.setState({ hasQuery: true })
      return
    }

    if (refinementList) {
      const list = refinementList['tags.key']
      if (list && list.length > 0) {
        this.setState({ hasQuery: true })
        return
      }
    }

    this.setState({ hasQuery: false })
  }

  private getRefinementControls() {
    if (!this.state.hasQuery) {
      return null
    }

    const { acceptedTags } = this.props

    return (
      <div className="row widget-resources-search__refinements">
        <div className="col-2 d-none d-lg-flex align-items-center">
          <Stats
            translations={{
              stats: this.getResultStats,
            }}
          />
        </div>
        <div className="col-8 p-0">
          <CustomCurrentRefinements
            acceptedTags={acceptedTags}
          />
        </div>
        <div className="col-4 col-lg-2 d-flex justify-content-end align-items-center">
          <CustomClearRefinements clearsQuery />
        </div>
      </div>
    )
  }

  private getResultStats(numHits, _) {
    let stats = `Viewing ${numHits} post`
    if (numHits > 1) {
      stats += 's'
    }
    return stats
  }

  // This needs to be here so we don't destroy and then have to
  // rerender the components again.
  private shouldSectionsBeHidden(): boolean {
    return this.state.hasQuery
  }

  private hasSections() {
    const { contenfulSections } = this.props

    return contenfulSections && contenfulSections.length != 0
  }

  private getContenfulSections() {
    const { contenfulSections } = this.props

    if (!this.hasSections()) { return }

    contenfulSections.forEach((id, idx) => {
      ContentfulView.fetchRenderedContenfulSection(
        ContentfulView.getSectionUrl(id),
        (body) => {
          this.setState((state, props) => {
            const { renderedSections, ...rest } = state
            renderedSections[id] = body

            return {
              renderedSections,
              ...rest,
            }
          })
        },
      )
    })
  }

  private transformTags = (items) => {
    const { acceptedTags } = this.props
    const acceptedKeys = acceptedTags.map((t) => t.key)

    return items
      .filter((item) => acceptedKeys.indexOf(item.label) >= 0)
      .map((item) => {
        let label = item.label
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < acceptedTags.length; i++) {
          if (label == acceptedTags[i].key) {
            label = acceptedTags[i].title
          }
        }

        return {
          ...item,
          label,
        }
      })
  }
}

export default ResourcesSearch
